<template>
    <b-row>
        <b-col lg="10" xl="8" offset-xl="2" offset-lg="1">
            <b-skeleton-wrapper :loading="loading">

                <template #loading>
                    <b-card class="mb-3">
                        <b-skeleton height="500px"></b-skeleton>
                    </b-card>

                </template>

                <b-card class="mb-3" body-class="pb-3">
                    <b-row class="app-card-header mb-3">
                        <b-col start>
                            <div class="app-card-tittle">
                                <div> {{trans('placement-calculation-elements',213)}}</div>    
                            </div>
                        </b-col>
                        <b-col cols="auto" end>
                            <b-spinner class="align-middle" small v-if="loading"></b-spinner>  
                        </b-col>
                    </b-row>
                    <b-row class="ml-1 mr-1 pb-2 app-local-row-header-font">
                        <b-col class="pl-1">
                            {{trans('full-name',3)}}
                        </b-col>
                        <b-col end cols="auto" class="app-width-min-210">
                            {{trans('flexworker',182)}}
                        </b-col>
                        <b-col end cols="auto" class="app-width-min-210">
                        {{trans('core-client',3)}}
                        </b-col>
                        <b-col end cols="auto" class="app-width-min-75">
                            
                        </b-col>
                    </b-row>
                    <b-list-group flush class="app-local-bottom-line" v-if="item_data.calculation_concept.items != null && item_data.calculation_concept.items.length > 0">
                        <b-list-group-item  class="p-2 ml-2 mr-2" v-for="item in item_data.calculation_concept.items" :key="item.index">
                            <b-row align-v="center" class="pl-2">
                                <b-col class="p-0">
                                        <span class="pl-1 app-local-row-font"> {{ item.name }} </span>
                                </b-col>
                                <b-col end cols="auto" class="app-width-min-210">
                                    <app-input v-model="item.person_value" type="decimal_02"/>
                                </b-col>
                                <b-col end cols="auto" class="app-width-min-210">
                                <app-input v-model="item.client_value" type="decimal_02"/>
                                </b-col>
                                <b-col end cols="auto" class="app-width-min-75">
                                <span class="pl-1 app-local-row-font">  </span>
                                </b-col>
                            </b-row>
                        </b-list-group-item>
                    </b-list-group>
                    <div v-else class="d-flex justify-content-center align-items-center app-table-no-records mb-3 mt-3">
                        {{trans('no-records-to-display',180)}}
                    </div>

                    <div v-if="item_data.components.items != null && item_data.components.items.length > 0">
                        <b-row class="mt-4 ml-1 mr-1 pb-2 app-local-row-header-font">
                            <b-col class="pl-1">
                                {{trans('placement-wage-component',213)}}
                            </b-col>
                            <b-col end cols="auto" class="app-width-min-210">
                                {{trans('flexworker',182)}}
                            </b-col>
                            <b-col end cols="auto" class="app-width-min-210">
                                {{trans('organization',169)}}  
                            </b-col>
                            <b-col end cols="auto" class="app-width-min-75">
                                
                            </b-col>
                        </b-row>
                        <b-list-group flush class="app-local-bottom-line" >
                            <b-list-group-item class="p-2 ml-2 mr-2" v-for="item in item_data.components.items" :key="item.index">
                                <b-row align-v="center" class="pl-2">
                                    <b-col class="p-0">
                                            <span class="pl-1 app-local-row-font"> {{ item.name }} </span>
                                    </b-col>
                                    <b-col end cols="auto" class="app-width-min-210">
                                        <app-input v-model="item.person_value" type="decimal_02"/>
                                    </b-col>
                                    <b-col end cols="auto" class="app-width-min-210">
                                    <app-input v-model="item.client_value" type="decimal_02"/>
                                    </b-col>
                                    <b-col end cols="auto" class="app-width-min-75">
                                        <b-dropdown  variant="link" no-caret right boundary="window">
                                            <template #button-content>
                                                <b-icon icon="three-dots-vertical" class="text-body"></b-icon>
                                            </template>
                                            <b-dropdown-item @click="removeComponent(item)">{{trans('remove',175)}}</b-dropdown-item>                               
                                        </b-dropdown>
                                    </b-col>
                                </b-row>
                            </b-list-group-item>
                        </b-list-group>
                    </div>
                </b-card>    
                <b-row class="mt-3">
                    <b-col>
                        <app-button type="primary" @click="addComponent">{{trans('placement-add-component',222)}}</app-button>
                        <app-button type="primary" @click="change" :loading="loading_change">{{trans('save', 175)}}</app-button>                    
                    </b-col>
                </b-row>
                <placement-profile-calculation-elements-add-component v-if="mComponentAdd.show" :show.sync="mComponentAdd.show" :args="mComponentAdd.args" :result="mComponentAdd.result" :return.sync="item_data" />
            </b-skeleton-wrapper>
        </b-col>    
    </b-row>
</template>
<script>

import axios from "@axios";
import PlacementProfileCalculationElementsAddComponent from './PlacementProfileCalculationElementsAddComponent.vue';
export default {

    components: {
        PlacementProfileCalculationElementsAddComponent
    },

    created(){
        this.getData();
    },

    watch: {

    },

    data(){
        return {
            loading: false,
            loading_change: false,
            item_data: {
                calculation_concept: {
                    items: null
                },
                components: {
                    items: null
                }
            },
            id_placement_data_main: this.$route.params.id_placement_data_main,
            mComponentAdd: { args: { }, result: 0, return: null, show: false},
        }
    },

    methods: {
        getData(){

            this.loading = true;
            axios
                .get('planning/placements/changeCalculationData/'+ this.$route.params.id_placement_data_main)
                .then((res) => {
                    this.item_data = res.data
                })
                .catch((error) => {
                    console.error(`error: ${error}`);
                })
                .finally(() => {
                    this.loading = false;
                });

        },
        addComponent() {
            this.mComponentAdd.show = true;
        },

        removeComponent(item) {
            this.item_data.components.items = this.item_data.components.items.filter(element => element !== item);
        },

        change(){

            this.loading_change = true;
            axios
                .post('planning/placements/changeCalculation', {
                    item_data: JSON.stringify(this.item_data),
                    id_placement_data_main: this.id_placement_data_main
                })
                .then((res) => {
                    
                })
                .catch((error) => {
                    console.error(`error: ${error}`);
                })
                .finally(() => {
                    this.loading_change = false;
                });

        }
    }
    
}
</script>
<style scoped>
.app-local-row-font {
    color: #174066;
    font-weight: 300;
    font-size: 14px;
}

.app-local-row-header-font {
    font-weight: 600;
    color: #1f627f;
    font-size: 14px;
    text-transform: none;
    letter-spacing: 1px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.05);
}

.app-local-bottom-line {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
</style>